import globalAxios from "axios";

import { DefaultApi } from "@client/api";

import fetchAdapter from "@utils/axiosFetchAdapter";

const tokenPromise = {
  resolve: undefined as undefined | ((callback: () => Promise<string>) => void),
};
const tokenContainer = {
  getAccessTokenCallback: new Promise<() => Promise<string>>((resolve) => {
    tokenPromise.resolve = resolve;
  }),
};

export function setAccessTokenCallback(callback: () => Promise<string>) {
  if (tokenPromise.resolve) {
    tokenPromise.resolve(callback);
  }
}

async function getAccessToken() {
  const callback = await tokenContainer.getAccessTokenCallback;
  const token = await callback();
  return token;
}

/**
 * Check if the given MIME is a JSON MIME.
 * JSON MIME examples:
 *   application/json
 *   application/json; charset=UTF8
 *   APPLICATION/JSON
 *   application/vnd.company+json
 * @param mime - MIME (Multipurpose Internet Mail Extensions)
 * @return True if the given MIME is JSON, false otherwise.
 */
function isJsonMime(mime: string): boolean {
  const jsonMime: RegExp = new RegExp(
    "^(application/json|[^;/ \\t]+/[^;/ \\t]+[+]json)[ \\t]*(;.*)?$",
    "i",
  );
  return (
    mime !== null &&
    (jsonMime.test(mime) ||
      mime.toLowerCase() === "application/json-patch+json")
  );
}

globalAxios.defaults.adapter = fetchAdapter;

const hostname = window.location.hostname;

let environment = "dev";
if (hostname === "atlas.magmutual.com") {
  environment = "prod";
} else if (hostname === "atlas.qa.magmutual.com") {
  environment = "qa";
} else if (hostname === "atlas.uat.magmutual.com") {
  environment = "uat";
}

export const basePath =
  import.meta.env.VITE_PUBLIC_BASE_URL ??
  `https://core-tasks-${environment}.gcp.magmutual.com`;

const apiClient = new DefaultApi({
  basePath: basePath,
  // NOTE: For SOME unknown reason, when running tests, the `getAcessToken` callback
  // does not seem to fire and fails to give us a token... so no requests fire in the tests.
  // Since we are going to mock the result anyways, we are overriding the value here in order to
  // allow requests to fire and then intercept them.
  accessToken: import.meta.env.VITEST_WORKER_ID ? "" : getAccessToken,
  isJsonMime,
});

export default apiClient;

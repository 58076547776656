import { RingLoader } from "react-spinners";

import Styles from "@components/util/sass/Loading.module.scss";

export default function Loading() {
  return (
    <div className={Styles.loadingSpinnerContainer}>
      <RingLoader className={Styles.loadingSpinner} color="#0f62fe" />
    </div>
  );
}

import { XCircleIcon } from "lucide-react";
import { type ErrorInfo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import Styles from "./sass/ErrorHandler.module.scss";

interface CustomError {
  errorTitle?: string;
  errorMessage?: string;
  hideDebug?: boolean;
}

const defaultError: CustomError = {
  errorTitle: "Critical Error",
  errorMessage:
    "A critical error occurred and the application cannot continue. This incident has been recorded. Please restart the application.",
};

const customErrors: Record<string, CustomError> = {
  "network error": {
    errorTitle: "Network Error",
    errorMessage:
      "Unable to connect to the server. Are you connected to MagMutual's private network (VPN or in-office connection)?",
    hideDebug: true,
  },
};

function findCustomError(error: Error): CustomError | undefined {
  const errorString = error.toString().toLowerCase();
  for (const [key, value] of Object.entries(customErrors)) {
    if (errorString.includes(key)) {
      return value;
    }
  }
  return undefined;
}

let renderedError = false;

function tryErrorReset(resetError: Function | undefined) {
  // This is quite, quite hackish!!! We are trying to get around Sentry's basically useless error reset in some cases.
  // If we encounter the same error without unmounting, we want to force a reload.
  try {
    renderedError = true;
    resetError?.();
  } catch (e) {
    // Sentry.close(2000).then(() => {
    // TODO: investigate: is there a way to "shut down" splunk like this?
    window.location.reload();
    // });
  }
}

function ErrorFallback({ error, componentStack, eventId, resetError }) {
  const { errorTitle, errorMessage, hideDebug } =
    findCustomError(error) || defaultError;
  if (renderedError) {
    // Sentry.close(2000).then(() => {
    // TODO: investigate: is there a way to "shut down" splunk like this?
    window.location.reload();
    // });
  }
  return (
    <div className={Styles.container}>
      <div>
        <h3 className={Styles.title}>
          <XCircleIcon aria-hidden="true" />
          &nbsp;{errorTitle || defaultError.errorTitle}
        </h3>
        <p className={Styles.message}>
          {errorMessage || defaultError.errorMessage}
        </p>
        {!hideDebug && (
          <>
            <br />
            <pre className={Styles.error}>{error.toString()}</pre>
            <br />
            <div className={Styles.inner}>
              <p>
                <span className={Styles.event}>{eventId}</span>
              </p>
              <pre className={Styles.code}>{componentStack}</pre>
            </div>
          </>
        )}
        <br />
        {/* <button kind="danger" onClick={() => tryErrorReset(resetError)}>
          Restart
        </button> */}
        {/* {!hideDebug && (
          <Button
            kind="secondary"
            onClick={() => {
              // TODO:
              //    consider creating a form so users can reporting errors
              //    remove false which is permanently hiding this button
              // Sentry.showReportDialog({ eventId });
            }}
          >
            Provide Additional Information
          </Button>
        )} */}
      </div>
    </div>
  );
}

function ErrorHandler({ children }) {
  const [componentStack, setComponentStack] = useState("");
  const [eventId, setEventId] = useState(null);

  const logError = (error: Error, info: ErrorInfo) => {
    setComponentStack(info.componentStack);

    // TODO:
    //    actually log the error
    //    get the event id back and display it
    setEventId(Math.random());
  };

  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <ErrorFallback
          {...props}
          componentStack={componentStack}
          eventId={eventId}
        />
      )}
      onError={logError}
      onUnmount={() => {
        renderedError = false;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorHandler;
